import React from "react";
import "../styles/about.scss";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Boy Golden | About & Contact</title>
        <meta
          name="description"
          content="Catch Boy Golden live on tour! Check out our updated tour dates and locations for an unforgettable musical experience. Don't miss your chance to see a live show – get your tickets now!"
        />
      </Helmet>

      <div className="about--content-container">
        <Navbar />
        <div className="about-page">
          <div className="bio-container">
            <div>
              <StaticImage
                src="../images/boy-golden-bio-headshot.jpg"
                alt="Musician"
                className="bio-image"
              />
            </div>
            <div className="bio-text">
              <h1 className="about-heading">About Boy Golden</h1>
              <i>
                <p>
                  From stoner-boy to country-boy, blue-boy to a
                  golden-voiced-man, Boy Golden has embodied them all. Now, as
                  our guide, Boy Golden leads us through this next experience
                  with kindness and humour, straight down Highway 5 and headlong
                  into <em>For Eden</em>.
                  <br />
                  <br /> Ten stories told from his heart, his dreams and his
                  real life, For Eden is about searching for things — like
                  dreams, love and better versions of himself, all wrapped up in
                  some idea of a perfect place, be it real or imagined. But
                  Paradise isn’t an outer realm; it’s the peace Boy Golden’s
                  found within.
                  <br /> <br /> Since his debut, Church of Better Daze (2020),
                  Boy Golden has been immersed in the journey: one where he’s
                  traveled inward towards himself and the other traversing the
                  observable world. Touring for hundreds of days, spending time
                  at home in the studio alone or with friends, Boy Golden has
                  been writing, writing, writing. From the lyrics, to the music,
                  to the accompanying behind the scenes stories and newsletters,
                  Boy Golden’s honesty and clarity are magnificent. At times
                  they magnify small wells of sadness, bringing forth the tears;
                  other times they widen smiles, decimate guilt and fear, and
                  others still, his songwriting eases the boundaries between
                  folk, bluegrass, Americana and pop music. <br />
                  <br />A compass and a lighthouse, a roadmap and a set of
                  postcards, For Eden forms a neat collection of experiences,
                  remembered with tenderness and beauty. It serves as a travel
                  companion for others on their respective journeys. It’s nice
                  to have someone along for the ride — and someone waiting for
                  you at home.
                  <br />
                  <br />
                  If home is where Boy Golden’s heart is, be it in the big city,
                  tiny town, on the road or on a record, <em>For Eden</em> beats
                  solidly, passionately, emphatically for a life and for love in
                  the moments we have.
                </p>
              </i>
            </div>
          </div>
          <div id="contact"></div>
          <div className="contact-container">
            <h2 className="about-heading">Contacts</h2>
            <h3 className="txt-primary center-text contact-heading">
              Management:
            </h3>
            <div className="contact-subtext">
              <h5 className="txt-highlight ">Watchdog Management: </h5>
              <a href="mailto:info@watchdogmgt.com">
                <h5 className=" ">info@watchdogmgt.com </h5>{" "}
              </a>
            </div>
            <br />
            <div className="contact-subtext">
              <div>
                <h5 className="txt-highlight contact-heading">
                  Publicity Contact:{" "}
                </h5>
                <a href="mailto:emilysmart@sixshooterrecords.com">
                  <h5 className=" ">emilysmart@sixshooterrecords.com</h5>{" "}
                </a>
              </div>
              <div>
                <h5 className="txt-highlight contact-heading">
                  Sync & Licensing:{" "}
                </h5>
                <a href="mailto:its.boy.golden@gmail.com">
                  <h5 className=" ">its.boy.golden@gmail.com</h5>{" "}
                </a>
              </div>
              <div>
                <h5 className="txt-highlight contact-heading">General: </h5>
                <a href="mailto:info@sixshooterrecords.com">
                  <h5 className=" ">info@sixshooterrecords.com</h5>{" "}
                </a>
              </div>
              <div>
                <h5 className="txt-highlight contact-heading">Label: </h5>
                <a
                  href="https://sixshooterrecords.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h5 className=" ">www.sixshooterrecords.com</h5>{" "}
                </a>
              </div>
            </div>
            <div className="contact-subtext">
              <div>
                <h5 className="txt-highlight contact-heading">Agents: </h5>
                <h5 className="txt-highlight ">
                  Jeremy Giacomin - Paquin Artists Agency (Canada)
                </h5>{" "}
                <a href="mailto:jeremy@paquinartistsagency.com">
                  <h5 className=""> jeremy@paquinartistsagency.com</h5>{" "}
                </a>
                <h5 className="txt-highlight ">
                  Dan Kuklinski - William Morris Entertainment (US)
                </h5>{" "}
                <a href="mailto:dkuklinski@wmeagency.com">
                  <h5 className=""> dkuklinski@wmeagency.com</h5>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
